import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import logo from '../assets/images/logo.png';
import hamburgermenu from '../assets/images/hamburgermenu.png';
import profile from '../assets/images/profile.png';
import IcChat from '../assets/images/ic_chat.svg';
import IcBell from '../assets/images/ic_bell.svg';
import apiService from '../core/apiService';
import moment from 'moment';
import { generateImage } from '../helpers/scrollToTop';

const TopNav = ({ refresh, handleClick }) => {
	const userData = useSelector(state => state.userData);
	const [isProfileAction, setisProfileAction] = useState(false);
	const showProfileAction = () => {
		setisProfileAction(isProfileAction === false ? true : false);
	};
	const navigate = useNavigate();
	const [isMessageAction, setisMessage] = useState(false);
	const showAction = () => {
		setisMessage(isMessageAction === false ? true : false);
	};
	const baseUrl = process.env.REACT_APP_URL + '/public/';
	const [dashboardDetails, setDashboardDetails] = useState([])

	console.log(userData, "userDaatata")
	const [isnotification, setisnotification] = useState(false);
	const shownotification = () => {
		setisnotification(isnotification === false ? true : false);
	};
	const [ismessage, setismessage] = useState(false);
	const showmessage = () => {
		setismessage(ismessage == false ? true : false);
	};
	const handle =()=>{
		navigate('/restaurant-communication/general', { replace: true })
	}
	const handles =()=>{
		navigate('/restaurant-communication/Request', { replace: true })
	}
	const location = useLocation();

	let heading = '';
	let loc = location.pathname.split('/');
	if (loc[1] === 'dashboard') {
		heading = 'Dashboard';
	} else if (loc[1] === 'accounts') {
		heading = 'Restaurant Management';
	} else if (loc[1] === 'game-day-special') {
		heading = 'Game Day Special';
	} else if (loc[1] === 'user-management') {
		heading = 'Management';
	} else if (loc[1] === 'admin-membership') {
		heading = 'Membership';
	} else if (loc[1] === 'promotions') {
		heading = 'Promotions';
	} else if (loc[1] === 'restaurant-communication') {
		heading = 'Communication';
	} else if (loc[1] === 'master-entry') {
		heading = 'Master Entry';
	} else if (loc[1] === 'profile-setting') {
		heading = 'Profile ';
	}	else if (loc[1] === 'advertisement') {
		heading = 'Advertisement';
	}
	
	useEffect(() => {
		apiService.get(`/getCommunications`, {}, ({ err, data }) => {

			console.log(data.data.success, "data1")

			setDashboardDetails(data.data);

		});

	}, [])
	console.log(dashboardDetails
		, "dashboardDetails")

	let filterrestaurant = dashboardDetails.filter(item => item.status === 0

	)
	console.log(filterrestaurant.length, "ghfhj")



	return (

		<div className="top-nav">
			<div className="dashboard-logo">
				<Link to='/dashboard'><img alt="" src={logo} className="small-logo" /></Link>
			</div>
			<button className="toggle-menu" onClick={handleClick} ><img alt="" src={hamburgermenu} /></button>
			<h3 className="page-name">{heading}</h3>
			<div className="profile-info">
				<div className='profile-info-box' onClick={showProfileAction}>
				<img src={generateImage(userData?.username ? userData?.username :''   )} alt='team_logo' width='100'  />

					{/* <img alt="" src={profile} /> */}
					<div className="profile-name">
						<h5>{userData?.username}</h5>
						<span>Welcome</span>
					</div>
					<span className="profile-icon">
						<FontAwesomeIcon icon={faAngleDown} />
						<i className="fas fa-angle-down"></i>
					</span>
				</div>
				{isProfileAction === true ?
					<>
						<div className="profile-tooltip show">
							<Link onClick={showProfileAction} to='/profile-setting'>Profile Setting</Link>
							<Link to=''>Logout</Link>
						</div>
					</> : <></>
				}

			</div>
			<div className="message-notificaion" onClick={() => { setismessage(prev => !prev) }}  >
				{
					filterrestaurant.length ? <div className="notification-icon" title="General Request from Restaurant" >
						<img alt="" src={IcChat} />
						<span></span>
					</div> : <div className="notification-icon" title="General Request from Restaurant" >
						<img alt="" src={IcChat} />
						{/* <span></span> */}
					</div>
				}

				{ismessage === true ?
					<div className="notification-pop" onMouseLeave={() => { setismessage(false) }}>
						{/* <Link onClick={showAction} to='/restaurant-communication/general'> */}
							{filterrestaurant.length ?
								<>
									{filterrestaurant?.map(item => {

										return <div className="notificaion-box">
											{/* <img alt="" src={profile} /> */}

											<div className="notification-con">
												{/* <p> Admin has {` ${((item?.status) ? 'Accepted' : "Rejected")} your Request for  ${item?.subject}`}</p> */}
												<p>{item?.subject}</p>
												<div className="notifaction-date-time">
													<span><i className="far fa-clock"></i> {
														moment(item?.updated_at).format('DD-MMM, YYYY HH:MM')}</span>

												</div>
											</div>
										</div>
									})
									}
								<div style={{cursor:'pointer',float:'center',color:'#b72727',fontSize:'15px'}} onClick={handle}>See more</div>
								{/* <div style={{cursor:'pointer',float:'left',color:'#b72727',fontSize:'15px'}} onClick={handles}>See more requests</div> */}

								</> :
								<div className="notificaion-box">
									<p>No notifications yet</p>
								</div>
							}
						{/* </Link> */}
					</div>

					: <></>
				}

			</div>
			{/* <div className="message-notificaion" onClick={shownotification}>
				<div className="notification-icon" title="verification of business">
					<img alt="" src={IcBell} />
					<span></span>
				</div>
				{isnotification === true ?
					<div className="notification-pop">
						<div className="notificaion-inner">
							<h4 className="notification-title">Today</h4>
							<span className="unread-noti-btn">Unread(3)</span>
							<div className="notificaion-box">
								<img alt="" src={profile} />
								<div className="notification-con">
								Your Account has been succesfully verified
									<div className="notifaction-date-time">
										<span><i className="far fa-clock"></i> 8:30PM</span>
									</div>
								</div>
							</div>
						
							
						</div>
					</div>
					: <></>
				}
			</div> */}

		</div>
	)
}

export { TopNav };
