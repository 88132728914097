import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPencilAlt,
  faTimes,
  faCalendarAlt,
  faSearch,
  faCircleNotch,
  faMapMarkerAlt,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import poweredByGoogle from "../../assets/images/powered-by-google.png";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router";
import { useForm, Controller } from "react-hook-form";
import Filter from "../../assets/images/filter-ico.svg";
import apiService from "../../core/apiService";
import moment from "moment";
import { REACT_APP_LIMIT } from "../../apiServices/apiServices";
const DailySpecial = () => {
  const [isFilter, setisFilter] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    control,
    trigger,
    reset,
    formState: { errors },
    setValue,
  } = useForm();
  const showFilter = () => {
    setisFilter(isFilter === false ? true : false);
  };

  console.log(process.env.REACT_APP_URL, "process.env.REACT_APP_URL");
  const apiKey = "AIzaSyCnTxLr5HmtfFxwlFqiLuqnaUKRPhyTFZ8";
  console.log(errors?.title, "errors?.titleerrors?.title");
  const [coordinates, setCoordinates] = useState(null);
  const [placeId, setPlaceId] = useState(null);
  const [imageType, setImageType] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [messagesList, setMessagesList] = useState([]);
  const [messagesList1, setMessagesList1] = useState([]);

  const [value, setValues] = useState(null);
  const [show, setShow] = useState(false);
  const [starterror, setStarterror] = useState("");
  const navigate = useNavigate();
  const [appliedFilters, setAppliedFilters] = useState({
    locationName:"",
    search: "",
    start_date: "",
    end_date: "",
  });
  const [dataLoading, setDataLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [imagePreview, setImagePreview] = useState(null);
  const [total, setTotal] = useState([]);
  const [restaurants, setRestaurants] = useState([]);
  const [selectedrestaurant, setSelectedRes] = useState(null);

  const [modal, setModal] = useState({
    type: "",
    data: null,
    title: "",
  });
  const handleClose = () => {
    setShow(false);
    setErrorMessage("");
    reset();
    setModal({
      type: "",
      data: null,
      title: "",
    });
  };

  const handleShow = (args) => {
    setImagePreview(null);
    console.log(args, "argsargs");
    setModal({
      data: args?.data || "",
      type: args?.type || "",
      title: args?.title || "",
    });
    if (args?.type == "Update") {
      setValue("title", args?.data?.ad_tile);
      setValue("banner_url", args?.data?.ad_banner_url);
      setValue("status_id", args?.data?.status_id);
      setValue("locations", args?.data?.location);
      setSelectedRes(args?.data?.restaurant_id );


      // setValue("image", args.data.ad_banner_image);
      //setValue('image', args?.data?.image);

      setImagePreview(
        `${process.env.REACT_APP_URL}/public/${args?.data?.ad_banner_image}`
      ); // Set the image path for preview
    }else{
      
      setSelectedRes(null );

    }

    setShow(true);
  };
  useEffect(() => {
    apiService.get(`/get/allRestaurantsforAdv`, {}, ({ err, data }) => {
      if (err) {
        // setNoListError(err.error);
        console.log(err);
        setisFilter(false);
        setDataLoading(false);

        return;
      }
      // setMessagesList(data.data.rows);
      // setTotal(data.data.count);
      console.log(data.data, "hsdgjhfdjd");
      let temp = data?.data?.map((food) => ({
        value: food.id,
        label: (
          <>
            {food.restaurant_name}
            {food.verified === 1 ? (
              <span
                className="badge rounded-pill bg-success"
                style={{ marginLeft: "8px" }}
              >
                <FontAwesomeIcon icon={faCheck} /> Verified
              </span>
            ) : (
              <span
                className="badge rounded-pill bg-secondary"
                style={{ marginLeft: "8px" }}
              >
                Not Verified
              </span>
            )}
          </>
        ),
      }));
      setRestaurants([...temp]);
      // setRestaurants(data.data);
      setDataLoading(false);
      setisFilter(false);
    });
  }, []);
console.log(apiKey,placeId,'fkdgjgjkfgjiogjuo')
useEffect(() => {
  setPlaceId(value?.value?.place_id);
}, [value]);
  useEffect(() => {
    // Load the Google Maps JavaScript API
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    script.onload = () => {
      // Initialize the Places Service
      const placesService = new window.google.maps.places.PlacesService(
        document.createElement("div")
      );

      // Request details for the specified place ID
      placesService.getDetails(
        { placeId: placeId, fields: ["geometry"] },
        (place, status) => {
          if (status === "OK") {
            const ad_latitude = place.geometry.location.lat();
            const ad_longitude = place.geometry.location.lng();
            console.log(ad_latitude, ad_longitude,'ad_latitude, ad_longitude')
            setCoordinates({ ad_latitude, ad_longitude });
          } else {
            console.error("Error fetching place details:", status);
          }
        }
      );
    };

    // Cleanup the script element on unmount
    return () => {
      document.body.removeChild(script);
    };
  }, [apiKey,placeId]);

  const onImageUpload = (e) => {
    const file = e.target.files[0];
    const maxSize = 256000;
    setImagePreview(null);
    if (file && file.size > maxSize) {
      setErrorMessage("Image size should be less than or equal 250kb");
      setImageType(false);
      setValue("image", null);
      setImagePreview(null); // Clear preview if the image is too large
    } else {
      setImageType(true);
      setErrorMessage("");
      setValue("image", file);

      // Set image preview
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result); // Set the preview URL
      };
      if (file) {
        reader.readAsDataURL(file);
      }
    }
  };
  console.log(value?.value?.description, "location");
  const sendMessage = (values) => {
    console.log(values, "values");
    if (values.banner_url && selectedrestaurant > 0) {
      setStarterror(
        "Please select either a banner URL or a restaurant, not both."
      );
      return;
    }
    // if (!values.banner_url && !(selectedrestaurant > 0)) {
    //   setStarterror(
    //     "Please select at least one option: a banner URL or a restaurant."
    //   );
    //   return;
    // }
   if(values.banner_url || selectedrestaurant > 0){
    setStarterror(
      ""
    );
   }

    if (!errorMessage.length) {
      console.log(values);

      let api = "/add/advertisement";
      if (modal?.type === "Update") {
        api = `/updateAdv/advertisement?id=${modal?.data?.id}`;
      }

      const ad_locations = value?.value?.description;
      const ad_address = value.value.terms[0]?.value;
      const ad_city = value.value.terms[1]?.value;
      const ad_state = value.value.terms[2]?.value;
      const ad_country = value.value.terms[3]?.value;
      const data = {
        ad_address,
        ad_city,
        ad_state,
        ad_country,
        ad_locations,
        ...values,
        ...coordinates,
        restaurant_id : selectedrestaurant
      };

      console.log(data,'dfgfgdfgdfg')
      
      // if (selectedrestaurant > 0) data.restaurant_id = selectedrestaurant;
      if (data.image == undefined) delete data.image;
      // if (data.banner_url == null) delete data.banner_url;

      console.log(data,'dfgfgdfgdfg')
      
      setIsLoading(true);
      apiService.post(
        api,
        data,
        ({ err, data }) => {
          console.log(err, "values");
          if (err) return setIsLoading(false);
          setIsLoading(false);
          setValue("title", values.title);
          setValue("banner_url", values.banner_url);
          // CustomSuccess('Saved successfully', 'Success')
          if (modal?.type === "Update") {
            let index = messagesList?.findIndex(
              (message) => message.id === modal?.data?.id
            );
            let updatedMessagesList = [...messagesList];
            updatedMessagesList[index] = data.data[0];
            setMessagesList(updatedMessagesList);
            // CustomSuccess('Updated successfully', 'Success')
            setIsLoading(false);
          } else {
            handleClose();
            setMessagesList([data.data, ...messagesList]);
            setIsLoading(false);
          }
          setIsLoading(false);

          handleClose();
          getData();
        },
        { isFormData: true }
      );
    }
  };
  const statusList = [
    { id: 0, value: "Paused" },
    { id: 1, value: "Active" },
  ];
  useEffect(() => {
    setDataLoading(true);
    getData();
  }, [currentPage]);
  const updateData = (page) => {
    setCurrentPage(page);
  };

  let lastPage = Math.ceil(total / REACT_APP_LIMIT);

  const nextPage = () => {
    if (currentPage < lastPage) {
      updateData(currentPage + 1);
    }
  };

  console.log(selectedrestaurant, "selectedrestaurant");

  const previousPage = () => {
    updateData(currentPage - 1);
  };
  const resetFilters = () => {
    setAppliedFilters({
      search: "",
      start_date: "",
      end_date: "",
      locationName:"",

    });
    getData({
      filters: {
        locationName:"",
        search: "",
        start_date: "",
        end_date: "",
      },
    });
  };
  useEffect(()=>{
    apiService.get(`/get/advertisementloc`, {}, ({ err, data }) => {
      if (err) {
        // setNoListError(err.error);
        console.log(err);
        setisFilter(false);
        setDataLoading(false);
        setMessagesList1([]);
        return;
      }
     
      setMessagesList1(data.data);

    });
  },[])
  const getData = (args) => {
    setDataLoading(true);
    let params = {
      pageno: currentPage,
      search: appliedFilters.search,
      search: appliedFilters.search,
      start_date: appliedFilters.start_date,
      locationName: appliedFilters.locationName,
    };

    if (args?.filters) {
      params = { ...params, ...args?.filters };
    }
    apiService.get(`/get/advertisement`, params, ({ err, data }) => {
      if (err) {
        // setNoListError(err.error);
        console.log(err);
        setisFilter(false);
        setDataLoading(false);
        setMessagesList([]);
        return;
      }
      setMessagesList(data.data.rows);
       setTotal(data.data.count);
      setDataLoading(false);
      setisFilter(false);
    });
  };
console.log(selectedrestaurant,'selectedrestaurant')
  const communicationForm = modal?.type !== "delete" && (
    <div id="add-user-form">
      <form onSubmit={handleSubmit(sendMessage)}>
        <div className="custom-pop-body">
          <h4 className="pop-title text-center">{modal.title || ""}</h4>
          <div className="pop-form">
            <div className="mb-3">
              <label>Title</label>
              <input
                maxLength={45}
                {...register("title", { required: "This field is required" })}
                type="text"
                id="name"
                name="title"
                className="form-control"
              />
              {errors?.title && (
                <span className="error name-error">
                  {errors?.title?.message}
                </span>
              )}
            </div>
            <div className="mb-5">
              <label>Url</label>
              <input
                maxLength={500}
                {...register("banner_url")}
                id="email"
                className="form-control"
              ></input>
              <div className="msgarea-msg"></div>
              {errors?.banner_url && (
                <span className="error name-error">
                  {errors?.banner_url?.message}
                </span>
              )}
            </div>
            <div className="mb-5">
              <label>Choose Restaurant</label>

              <Select
                classNamePrefix="form-control"
                onChange={(selectedOption) => {
                  setSelectedRes(selectedOption ? selectedOption.value : null);
                }}
                value={restaurants.filter(function (option) {
                  return option.value === selectedrestaurant 
                })}

                // value={selectedrestaurant}
                options={restaurants}
                isClearable
                
              />

              <span className="error name-error">{starterror}</span>
            </div>
            <div className="mb-3">
              <div className=" d-flex justify-content-between align-items-center">
                <label>Image</label>
                <div>
                  {imagePreview && (
                    <div className="mb-1">
                      <img
                        src={imagePreview}
                        alt="Preview"
                        style={{ width: "100px", height: "auto" }}
                      />
                    </div>
                  )}
                </div>
              </div>

              <Controller
                name="image"
                control={control}
                rules={{
                  required:
                    modal.type !== "Update" ? "Image is required" : false, // Conditionally apply required validation
                }}
                render={({ field: { onChange, ref } }) => (
                  <input
                    onChange={(e) => {
                      onImageUpload(e); // Call your existing image upload handler
                      onChange(e.target.files[0]); // Update react-hook-form value
                      trigger("image"); // Manually trigger validation
                    }}
                    type="file"
                    className="form-control"
                    accept="image/png, image/gif, image/jpeg, image/jpg"
                    ref={ref}
                  />
                )}
              />

              {!imageType && (
                <span className="error password-error">{errorMessage}</span>
              )}
              {errors.image && (
                <span className="error name-error">{errors.image.message}</span>
              )}
            </div>

            <div className="mb-4">
              <label>Enter Location</label>
              <div className="input-icon">
                <span className="input-icon-alt">
                  <FontAwesomeIcon icon={faMapMarkerAlt} />
                </span>
                <Controller
                  name="location"
                  control={control}
                  rules={{ required: "Location is required" }}
                  render={({ field: { onChange, value, ref } }) => (
                    <GooglePlacesAutocomplete
                      apiKey={apiKey}
                      debounce={800}
                      selectProps={{
                        value: value,
                        onChange: (selectedValue) => {
                          // Update local state and react-hook-form's state
                          setValues(selectedValue);
                          onChange(selectedValue); // Pass value to react-hook-form
                        },
                        placeholder: "Search",
                        styles: {
                          input: (provided) => ({
                            ...provided,
                            padding: "10 0",
                          }),
                        },
                      }}
                    />
                  )}
                />
                {errors.location && (
                  <span className="error name-error">
                    {errors.location.message}
                  </span>
                )}
                <img
                  alt="Powered by Google"
                  src={poweredByGoogle}
                  className="mt-2"
                />

                <div className="clear-input">
                  <button
                    type="button"
                    className="mt-2 btn btn-dark clear-btn"
                    onClick={() => {
                      setValues(null); // Clear local state
                      setValue("location", null); // Clear form state using react-hook-form
                    }}
                  >
                    Clear
                  </button>
                </div>
                {modal.type == "Update" ? (
                  <div className="mt-3">
                    <input
                      maxLength={225}
                      disabled
                      {...register("locations")}
                      type="text"
                      id="name"
                      name="locations"
                      className="form-control"
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div className="mb-4">
                <label>Enter Status</label>
                <select
                  {...register("status_id")}
                  className="form-control"
                  defaultValue="0" // Start with an empty value
                >
                  <option disabled value="">
                    Please Select Status
                  </option>
                  {statusList.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.value}
                    </option>
                  ))}
                </select>
                {errors?.status_id && (
                  <span className="error">{errors.status_id.message}</span>
                )}
              </div>
            </div>

 

            {/* <input {...register('restaurant_id', { required: 'This field is required', value: restaurant?.id })} type="hidden" className="form-control" /> */}
          </div>
        </div>
        <div className="custom-pop-footer">
          <div className="text-center">
            <button
              type="submit"
              className="btn btn-dark btn-long"
              disabled={isLoading}
            >
              {isLoading && imageType ? (
                <FontAwesomeIcon icon={faCircleNotch} spin />
              ) : (
                modal.type
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
  const deleteNotification = () => {
    apiService.remove(
      `/advertisement/delete?id=${modal?.data?.id}`,
      ({ err, data }) => {
        if (err) return console.log(err);
        setMessagesList(
          messagesList.filter((message) => message.id !== modal?.data?.id)
        );
        handleClose();
      }
    );
  };
  const deleteCommunicationForm = modal?.type === "delete" && (
    <div className="custom-pop-body">
      <h4 className="pop-title text-center">{modal.title || ""}</h4>
      <div>
        <p className="text-center">
          Are you sure you want to delete this advertisement?
        </p>
        <div className="custom-pop-footer">
          <div className="text-center">
            <button
              onClick={deleteNotification}
              type="submit"
              className="btn btn-danger btn-long"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <div className="dashboard-top-btn justify-content-end">
        <div className="table-filter">
          <button onClick={showFilter} className="btn btn-default filter-btn">
            <img alt="" src={Filter} />
            Filters
          </button>
        </div>
        <button
          className="btn btn-dark"
          onClick={() =>
            handleShow({
              type: "Create",
              title: "Setup Advertisement",
            })
          }
        >
          <FontAwesomeIcon icon={faPencilAlt} /> Create
        </button>
      </div>
      <div className="table-sec common-table margin-t30">
        <div className="table-responsive">
          <table className="table white-td-table">
            <thead>
              <tr>
                <th>Title</th>
                <th>Banner Url</th>
                <th>Restaurant Name</th>
                <th>Date & Time</th>
                <th>Location</th>
                <th>Banner Image</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody className={messagesList.length > 0 ? "" : "empty_row"}>
              {dataLoading ? (
                <tr>
                  <td colSpan={7}>
                    <div className="table-inner-loader">
                      <FontAwesomeIcon
                        style={{ fontSize: 25 }}
                        icon={faCircleNotch}
                        spin
                      />
                    </div>
                  </td>
                </tr>
              ) : messagesList?.length ? (
                messagesList?.map((message, index) => (
                  <tr key={index}>
                    <td>{message?.ad_tile}</td>
                    <td>{message?.ad_banner_url}</td>
                    <td>{message?.restaurant ? message?.restaurant?.restaurant_name : '' }</td>

                    <td>
                      {moment(message?.created_at).format("YYYY-MM-DD, hh:mm")}
                    </td>
                    <td>
                      {message?.location}
                    </td>
                    <td>
                      {message?.ad_banner_image ? (
                        <img
                          height={"100"}
                          width={"100"}
                          src={`${process.env.REACT_APP_URL}/public/${message.ad_banner_image}`}
                          alt=""
                          className="img-thumbnail"
                        />
                      ) : (
                        ""
                      )}
                    </td>
                    <td>
                      {message.status_id == 1 ? (
                        <button className="btn btn-success" disabled>
                          Active
                        </button>
                      ) : (
                        <button className="btn btn-danger" disabled>
                          Paused
                        </button>
                      )}
                    </td>

                    <td className="btn-aside table-action">
                      <button
                        className="btn btn-success"
                        onClick={() =>
                          handleShow({
                            type: "Update",
                            data: message,
                            title: "Setup Advertisement",
                          })
                        }
                      >
                        Update
                      </button>
                      <button
                        className="btn btn-danger"
                        onClick={() =>
                          handleShow({
                            type: "delete",
                            data: message,
                            title: "Delete Advertisemnet	",
                          })
                        }
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colspan="10">No records found!</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {isFilter === true ? (
        <>
          <div className="popup-overlay show"></div>
          <div id="filter-pop" className="show">
            <div className="filter-pop-inner">
              <div className="filter-head">
                <h4>Filters</h4>
                <button className="times-btn filter-close" onClick={showFilter}>
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
              <div className="filter-body">
                <div className="mb-4">
                  <div className="input-icon">
                    <span className="input-icon-alt">
                      <FontAwesomeIcon icon={faSearch} />
                    </span>
                    <input
                      value={appliedFilters.search}
                      onChange={(e) =>
                        setAppliedFilters({
                          ...appliedFilters,
                          search: e.target.value,
                        })
                      }
                      type="text"
                      className="form-control"
                      placeholder="Type here..."
                    />
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-md-6">
                    <div className="input-icon">
                      <span className="input-icon-alt">
                        <FontAwesomeIcon icon={faCalendarAlt} />
                      </span>
                      <input
                        value={appliedFilters.start_date}
                        onChange={(e) =>
                          setAppliedFilters({
                            ...appliedFilters,
                            start_date: e.target.value,
                          })
                        }
                        type="date"
                        className="form-control"
                        placeholder="Start Date"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-icon">
                      <span className="input-icon-alt">
                        <FontAwesomeIcon icon={faCalendarAlt} />
                      </span>
                      <input
                        value={appliedFilters.end_date}
                        onChange={(e) =>
                          setAppliedFilters({
                            ...appliedFilters,
                            end_date: e.target.value,
                          })
                        }
                        type="date"
                        className="form-control"
                        placeholder="End Date"
                      />
                    </div>
                  </div>
                </div>
                <div className='col-md-6'>
												<div className="form-group mb-4">
													<label className="form-label">Location</label>
													<select
														value={appliedFilters.locationName}
														className="form-control"
                            style={{width:425}}
														onChange={(e) => setAppliedFilters({ ...appliedFilters, locationName: e.target.value })}
													>
														<option disabled value=''>Select</option>
														{
															messagesList1?.length ?
                              messagesList1?.map((container, i) => (
																	<option key={i} value={container.location}>
																		{container.location}
																	</option>
																)) :
																<></>
														}
													</select>
												</div>
											</div>
              </div>
              <div className="filter-form-btns">
                <button
                  className="btn btn-dark full-width-btn add-couple"
                  onClick={() =>
                    getData({
                      filters: appliedFilters,
                    })
                  }
                  disabled={dataLoading}
                >
                  Apply Filter
                </button>
                <button
                  className="btn btn-dark full-width-btn add-couple mt-3"
                  onClick={resetFilters}
                >
                  Reset Filters
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <Modal
            show={show}
            className="common-pop popup-medium"
            onHide={handleClose}
          >
            <button
              type="button"
              className="btn-close"
              onClick={handleClose}
            ></button>
            <div className="custom-pop-inner">
              <div className="custom-pop-box">
                {communicationForm}
                {deleteCommunicationForm}
              </div>
            </div>
          </Modal>
          {lastPage > 1 ? (
            <>
              {
                <ul className="pagination justify-content-center">
                  <li
                    className={`page-item ${
                      currentPage === 1 ? "disabled" : ""
                    }`}
                  >
                    <button className="page-link" onClick={previousPage}>
                      Previous
                    </button>
                  </li>
                  {Array.from({ length: lastPage }).map((_, index) => (
                    <li
                      key={index}
                      className={`page-item ${
                        currentPage === index + 1 ? "active" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() => updateData(index + 1)}
                      >
                        {index + 1}
                      </button>
                    </li>
                  ))}
                  <li
                    className={`page-item ${
                      currentPage === lastPage ? "disabled" : ""
                    }`}
                  >
                    <button className="page-link" onClick={nextPage}>
                      Next
                    </button>
                  </li>
                </ul>
              }
            </>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
};

export default DailySpecial;
