import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faCalendarAlt,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "react-bootstrap/Modal";
import Filter from "../../assets/images/filter-ico.svg";
import apiService from "../../core/apiService";
import { Spinner } from "react-bootstrap";
import moment from "moment";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { REACT_APP_LIMIT } from "../../apiServices/apiServices";
const RestaurantPromo = () => {
  const format1 = "YYYY-MM-DD";
  const [isFilter, setisFilter] = useState(false);
  const [restaurantdata, setRestaurantData] = useState([]);
 
  const showFilter = () => {
    setisFilter(isFilter === false ? true : false);

  };
  const navigate = useNavigate();
  const [dataLoading, setDataLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [show, setShow] = useState(false);
  const [id, setId] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [promoCodeId, setPromoCodeId] = useState("");
  const [expiry, setExpiry] = useState("");
  const [total, setTotal] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  console.log(restaurantdata, "checking the restraunt data ");
  // const showFilter = () => {
  // 	setisFilter(isFilter === false ? true : false);
  // 	if (isFilter === true) {
  // 		handleSearch()
  // 	}

  // };
  const updateData = (page) => {
    setCurrentPage(page);
  };
  const handleSearch = () => {
    const params = {
      pageno: currentPage,
      // search: appliedFilters.search,
      // start_date: appliedFilters.start_date,
      // end_date: appliedFilters.end_date
    }; 
    apiService.get("/getPromo", params, ({ err, data }) => {
      if (err) return console.log(err);

      setRestaurantData(data.data.rows);
      setTotal(data.data.count);
      setDataLoading(false);
    });
  };
  useEffect(() => {
    handleSearch();
  }, [refresh, currentPage]);

  const lastPage = Math.ceil(total / REACT_APP_LIMIT);
  console.log(lastPage, "lastpage");
  const nextPage = () => {
    if (currentPage < lastPage) {
      updateData(currentPage + 1);
    }
  };
  const previousPage = () => {
    updateData(currentPage - 1);
  };
  const handleUpdate = (id, value, value1, value2) => {
    console.log(value, value1, value2, "valueuhfj");
    if (!(value || value1 || value2))
      toast.error("The input field cannot be left blank.", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        theme: "light",
      });
    else
      apiService.post(
        `/updatepromo/${id}`,
        { promoCode: value, promoCodeId: value1, expire_at: value2 },
        ({ err, data }) => {
          if (err) return console.log(err);
          setRefresh(!refresh);
        }
      );
  };
  const handleDelete = () => {
    apiService.remove(`/deletePromo/${id}`, ({ err, data }) => {
      if (err) {
        console.error(err);
      } else {
        // Assuming setRefresh is a state update function
        setRefresh(!refresh);
        handleClose();
      }
    });
  };
  const handleCreate = (promoCode, promoCodeId, expire_at) => {
    if (!(promoCode && promoCodeId && expire_at))
      toast.error("The input field cannot be left blank.", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        theme: "light",
      });
    else
      apiService.post(
        "/createpromo1",
        { promoCode, promoCodeId, expire_at },
        ({ err, data }) => {
          if (err) return console.log(err);
          setInputValue("");
          setPromoCodeId("");
          setExpiry();
          setRefresh(!refresh);
        }
      );
  };
  const updateState = (data, id, value) => {
    const newState = data.map((obj) => {
      // 👇️ if id equals 2, update country property

      if (obj.id === id) {
        return { ...obj, promoCode: value };
      }
      // 👇️ otherwise return the object as is
      return obj;
    });
    setRestaurantData(newState);
  };
  const updateStates = (data, id, value) => {
    const newState = data.map((obj) => {
      // 👇️ if id equals 2, update country property

      if (obj.id === id) {
        return { ...obj, promoCodeId: value };
      }
      // 👇️ otherwise return the object as is
      return obj;
    });
    setRestaurantData(newState);
  };
  const onhandle = (data, id, value) => {
    const newState = data.map((obj) => {
      // 👇️ if id equals 2, update country property

      if (obj.id === id) {
        return { ...obj, expire_at: value };
      }
      // 👇️ otherwise return the object as is
      return obj;
    });
    setRestaurantData(newState);
  };
  console.log(restaurantdata, "checking the restraunt data ");

  const handleClose = () => {
    setShow(false);
    setId("");
  };
  const handleShow = (id) => {
    setShow(true);
    setId(id);
  };
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = async (text,id) => {
    try {
      await navigator.clipboard.writeText(text);
      setIsCopied(true);
      setId(id)
      setTimeout(() => setIsCopied(false), 2000);
    } catch (err) {
      console.error("Failed to copy: ", err);
      setIsCopied(false);
    }
  };

  console.log(id,'jjjjjjj')

  return (
    <>
      <div className="dashboard-top-btn">
        <div className="back-redirect">
          <button
            className="btn btn-default bg-dark filter-btn text-light"
            onClick={() => navigate(-1)}
          >
            Back
          </button>
        </div>
        {/* <div className="table-filter">
					<button onClick={showFilter} className="btn btn-default filter-btn"><img alt="" src={Filter} />Filters</button>
				</div> */}
      </div>
      <div className="table-sec common-table margin-t30">
        <div className="table-responsive">
          <div className="table-sec common-table margin-t30 Beer-table ">
            <div className="table-responsive">
              <table className="table white-td-table">
                <thead>
                  <tr>
                    <th>Promo Code</th>
                    <th>Promo CodeID</th>
                    <th>Expiry</th>
                    <th>Promotional Link</th>
                    <th># Restaurants</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="food-categories">
                    <td>
                      <input
                        type="name"
                        className="form-control"
                        value={inputValue}
                        placeholder="eg.SAXRYQKT"
                        onChange={(e) => setInputValue(e.target.value)}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        value={promoCodeId}
                        placeholder="eg.SAXRYQKT"
                        onChange={(e) => setPromoCodeId(e.target.value)}
                      />
                    </td>
                    <td>
                      <input
                        type="date"
                        className="form-control"
                        placeholder="eg.SAXRYQKT"
                        value={expiry}
                        onChange={(e) => setExpiry(e.target.value)}
                      />
                    </td>
                    <td></td><td></td>
                    <td className="table-action">
                      <button
                        className="btn btn-success add "
                        onClick={() =>
                          handleCreate(inputValue, promoCodeId, expiry)
                        }
                      >
                        Add
                      </button>
                    </td>
                  </tr>
                  <>
                    {dataLoading ? (
                      <tr>
                        <td colSpan="6" className="text-center">
                          <Spinner />
                        </td>
                      </tr>
                    ) : restaurantdata.length ? (
                      <>
                        {restaurantdata?.map((item) => (
                          <tr>
                            <td>
                              <input
                                value={item.promoCode}
                                onChange={(e) =>
                                  updateState(
                                    restaurantdata,
                                    item.id,
                                    e.target.value,
                                    "promoCode"
                                  )
                                }
                                className="form-control"
                              />
                            </td>
                            <td>
                              <input
                                value={item.promoCodeId}
                                onChange={(e) =>
                                  updateStates(
                                    restaurantdata,
                                    item.id,
                                    e.target.value,
                                    "promoCodeId"
                                  )
                                }
                                className="form-control"
                              />
                            </td>

                            <td>
                              <input
                                type="date"
                                className="form-control"
                                value={moment
                                  .utc(item.expire_at)
                                  .local()
                                  .format(format1)}
                                placeholder="eg.SAXRYQKT"
                                onChange={(e) =>
                                  onhandle(
                                    restaurantdata,
                                    item.id,
                                    e.target.value,
                                    "expire_at"
                                  )
                                }
                              />
                            </td>
                            <td>
                              {moment.utc().local().format(format1) >
                              moment
                                .utc(item.expire_at)
                                .local()
                                .format(format1) ? (
                                <div
                                  className="copy-link"
                                  style={{ color: "red" }}
                                >
                                  Expired!
                                </div>
                              ) : (
                                <div
                                  className="copy-link"
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    handleCopy(
                                      `https://restaurant.whatsonlogin.com/register?promoCode=${item.promoCode}`,item.id
                                    )
                                  }
                                >
                                  <>
                                    {(isCopied && id == item.id) ? (
                                      <div
                                        className="copy-link"
                                        style={{ color: "green" }}
                                      >
                                        Copied!
                                      </div>
                                    ) : (
                                      <div
                                        className="copy-link"
                                        style={{ color: "black" }}
                                      >
                                        {" "}
                                        Copy link
                                      </div>
                                    )}
                                  </>
                                </div>
                              )}
                            </td>
                            <td>  <div
                                        className="copy-link"
                                        style={{ color: "black" }}
                                      >
                                   
                                       {item.restaurant?.restaurantCount?item.restaurant?.restaurantCount :0 }
                                      </div></td>

                            <td className="table-action">
                              <button
                                className="btn btn-success"
                                onClick={() =>
                                  handleUpdate(
                                    item.id,
                                    item.promoCode,
                                    item.promoCodeId,
                                    item.expire_at
                                  )
                                }
                              >
                                Update
                              </button>
                              <button
                                className="btn btn-danger"
                                onClick={() => handleShow(item.id)}
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                        ))}
                      </>
                    ) : (
                      <tr>
                        <td colSpan="6" className="text-center">
                          No PromoCode Found
                        </td>
                      </tr>
                    )}
                  </>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {isFilter === true ? (
        <>
          <div className="popup-overlay show"></div>
          <div id="filter-pop" className="show">
            <div className="filter-pop-inner">
              <div className="filter-head">
                <h4>Filters</h4>
                <button className="times-btn filter-close" onClick={showFilter}>
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
              <div className="filter-body">
                <div className="mb-4">
                  <div className="input-icon">
                    <span className="input-icon-alt">
                      <FontAwesomeIcon icon={faSearch} />
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Type here..."
                    />
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-md-6">
                    <div className="input-icon">
                      <span className="input-icon-alt">
                        <FontAwesomeIcon icon={faCalendarAlt} />
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Start Date"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-icon">
                      <span className="input-icon-alt">
                        <FontAwesomeIcon icon={faCalendarAlt} />
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="End Date"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="filter-form-btns">
                <button
                  className="btn btn-dark full-width-btn add-couple"
                  onClick={showFilter}
                >
                  Apply Filter
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <Modal
            show={show}
            className="common-pop popup-medium"
            onHide={handleClose}
          >
            <button
              type="button"
              className="btn-close"
              onClick={handleClose}
            ></button>
            <div className="custom-pop-inner">
              <div className="custom-pop-box">
                <div id="add-user-form">
                  <div className="custom-pop-body">
                    <h4 className="pop-title text-center">
                      Are You Sure you want to Delete !
                    </h4>
                  </div>
                  <div className="custom-pop-footer">
                    <div className="text-center">
                      <button
                        type="submit"
                        onClick={handleClose}
                        className="btn btn-secondary mx-2"
                      >
                        No
                      </button>
                      <button
                        type="submit"
                        onClick={handleDelete}
                        className="btn btn-danger"
                      >
                        Yes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
          {lastPage > 1 ? (
            <>
              {
                <ul className="pagination justify-content-center">
                  <li
                    className={`page-item ${
                      currentPage === 1 ? "disabled" : ""
                    }`}
                  >
                    <button className="page-link" onClick={previousPage}>
                      Previous
                    </button>
                  </li>
                  {Array.from({ length: lastPage }).map((_, index) => (
                    <li
                      key={index}
                      className={`page-item ${
                        currentPage === index + 1 ? "active" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() => updateData(index + 1)}
                      >
                        {index + 1}
                      </button>
                    </li>
                  ))}
                  <li
                    className={`page-item ${
                      currentPage === lastPage ? "disabled" : ""
                    }`}
                  >
                    <button className="page-link" onClick={nextPage}>
                      Next
                    </button>
                  </li>
                </ul>
              }
            </>
          ) : (
            <></>
          )}

          {/* <Modal show={show} className="common-pop popup-medium" onHide={handleClose}>
						<button type="button" className="btn-close" onClick={handleClose}></button>
						<div className="custom-pop-inner">
							<div className="custom-pop-box">
								<div id="add-user-form">
									<div className="custom-pop-body">
										<h4 className="pop-title text-center">Admin Communication</h4>
										<div className="pop-form">
											<div className="mb-3">
												<label>Type</label>
												<select className="form-control">
													<option></option>
												</select>
												<span className="error name-error">This field is required*</span>
											</div>
											<div className="mb-3">
												<label>Subject</label>
												<input type="text" className="form-control" />
												<span className="error name-error">This field is required*</span>
											</div>
											<div className="mb-5">
												<label>Description</label>
												<textarea id="email" className="form-control"></textarea>
												<span className="error email-error">This field is required*</span>
											</div>
										</div>
									</div>
									<div className="custom-pop-footer">
										<div className="text-center">
											<button type="submit" onClick={handleClose} className="btn btn-dark btn-long">Send</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</Modal> */}
        </>
      )}
    </>
  );
};

export default RestaurantPromo;
